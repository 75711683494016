
import React from 'react'
import {useIntl} from "gatsby-plugin-intl"

import Layout from '../../components/layout'


const AboutPage = ({data}) => {
  const intl = useIntl()
  return(
     <Layout>

      <br/>
      <br/>
      <div className='row'
      style={{
        minHeight:'100%'
      }}
      >
      <div className='col-8'>
      <h3>{intl.formatMessage({ id: "impressum.SeitenInhaber" })}</h3>
      <p>{intl.formatMessage({ id: "global.IstFicotex" })}<br /> {intl.formatMessage({ id: "global.CordGrashorn" })}
      
      <br />{intl.formatMessage({ id: "global.TomDykStraße" })}<br />{intl.formatMessage({ id: "global.Bremen" })}</p>
      <p>{intl.formatMessage({ id: "global.TelefonHb" })}<br />{intl.formatMessage({ id: "global.FaxHb" })}</p>

      <p>{intl.formatMessage({ id: "global.MailInfo" })}<br /><a href="http://www.ficotex.de">www.ficotex.de</a></p>
      <br/>
      <h3>{intl.formatMessage({ id: "impressum.Inhalt" })}</h3>
      <p>{intl.formatMessage({ id: "impressum.InhaltText1" })}</p>
      <br/>
     <h3>{intl.formatMessage({ id: "impressum.Verweise" })}</h3>
      <p>{intl.formatMessage({ id: "impressum.VerweiseText1" })}</p>
      <br/>
      <h3>{intl.formatMessage({ id: "impressum.Urheber" })}</h3>
      <p>{intl.formatMessage({ id: "impressum.UrheberText1" })}</p>
      <br/>
      <h3>{intl.formatMessage({ id: "impressum.Datenschutz" })}</h3>
      <p>{intl.formatMessage({ id: "impressum.DatenschutzText1" })}</p>
      <p>{intl.formatMessage({ id: "impressum.DatenschutzText2" })}</p>
      <br/>
     <h3>{intl.formatMessage({ id: "impressum.Rechtswirksamkeit" })}</h3>
      <p>{intl.formatMessage({ id: "impressum.RechtswirksamkeitText1" })}</p>

      </div>
      
      </div>

    </Layout>
  )

  }
export default AboutPage;

